import { fetchFeatureFlagsAPI } from '@/api/feature-flag.api';
import { FeatureFlagResponse } from '@/types/api/response/feature-flag';
import Cookies from 'js-cookie';

export const FEATURE_FLAG_PREFIX = 'FEATURE_FLAG_';

export type FeatureFlags = {
  [key: string]: boolean;
};

export function fromFeatureFlagKey(key: string): string {
  return key.replace(new RegExp(`^${FEATURE_FLAG_PREFIX}`), '').toLocaleUpperCase();
}

export function toFeatureFlagKey(key: string): string {
  return `${FEATURE_FLAG_PREFIX}${key.toLocaleUpperCase()}`;
}

export async function fetchFeatureFlags(): Promise<FeatureFlags> {
  const cookies = Cookies.get();
  const flags: FeatureFlags = {};

  Object.entries(cookies).forEach(([key, value]) => {
    if (key.startsWith(FEATURE_FLAG_PREFIX)) {
      flags[fromFeatureFlagKey(key)] = value === '1';
    }
  });

  return flags;
}

export async function fetchFeatureFlag(feature: string): Promise<FeatureFlagResponse> {
  return await fetchFeatureFlagsAPI(feature);
}

export async function storeFeatureFlag(name: string, value: boolean): Promise<void> {
  Cookies.set(toFeatureFlagKey(name), value ? '1' : '0');
}
