import { useState } from 'react';

const DesignToggle = () => {
  const [isV2Enabled, setIsV2Enabled] = useState(() => {
    return localStorage.getItem('v2DesignOverride') === 'true';
  });

  const handleToggle = (checked: boolean) => {
    localStorage.setItem('v2DesignOverride', checked.toString());
    window.location.reload();
  };

  return (
    <div className="fixed bottom-5 right-5 z-[9999]">
      <label className="flex items-center gap-2">
        <input
          type="checkbox"
          checked={isV2Enabled}
          onChange={(e) => handleToggle(e.target.checked)}
          className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
        />
        <span className="text-sm font-medium">V2 Design</span>
      </label>
    </div>
  );
};

export default DesignToggle;
