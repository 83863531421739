import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { fetchFeatureFlag } from './common/lib/feature-flags';
import DesignToggle from './DesignToggle';

(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  let v2Design;
  try {
    v2Design = await fetchFeatureFlag('V2_DESIGN');
  } catch (error) {
    console.error('Error fetching feature flag:', error);
    v2Design = false;
  }
  const isV2Enabled = localStorage.getItem('v2DesignOverride') === 'true';

  const RootComponent = lazy(() => (isV2Enabled ? import('./v2/root') : import('./legacy-root')));

  root.render(
    <React.StrictMode>
      <Suspense fallback={<div></div>}>
        <RootComponent />
        {v2Design.enabled && <DesignToggle />}
      </Suspense>
    </React.StrictMode>,
  );
})();
