import Cookies from 'js-cookie';
import { cookieNames } from '../constants';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type RequestOptions = {
  method?: HttpMethod;
  headers?: Headers;
  params?: Record<string, string | number>;
  body?: Record<string, unknown> | string;
  token?: string;
  credentials?: RequestCredentials;
};

export interface ApiResponse<T> extends Response {
  data: T;
  error: null;
}

class HttpError extends Error {
  constructor(
    public status: number,
    public data: unknown,
  ) {
    super(`HTTP Error ${status}`);
    this.name = 'HttpError';
  }
}

export class AuthError extends HttpError {
  constructor(data: unknown) {
    super(403, data);
    this.name = 'AuthError';
  }
}

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL;

const buildQueryString = (params: Record<string, string | number | boolean> = {}): string => {
  return new URLSearchParams(
    Object.fromEntries(Object.entries(params).map(([key, value]) => [key, String(value)])),
  ).toString();
};

async function getCSRFToken() {
  // Function not currently used
  try {
    const response = await fetch(BASE_URL + '/get-csrf', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const respAsJSON = await response.json();
    return respAsJSON.csrfToken;
  } catch (error) {
    console.warn('Error obtaining CSRF token:', error);
    return null;
  }
}

async function fetchWrapperFunction<T>(
  endpoint: string,
  options: RequestOptions = {},
): Promise<ApiResponse<T>> {
  const { method = 'GET', headers = {}, params = {}, body, token, credentials } = options;

  let CSRFToken = Cookies.get(cookieNames.csrfToken);

  if (!CSRFToken) {
    CSRFToken = await getCSRFToken();
  }

  const config: RequestInit = {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(CSRFToken ? { 'X-CSRFToken': CSRFToken } : {}),
      ...headers,
    }),
    credentials: credentials ?? 'include',
  };

  if (body) {
    config.body = typeof body === 'string' ? body : JSON.stringify(body);
  }

  const queryString = buildQueryString(params);
  const url = `${BASE_URL}${endpoint}${queryString ? `?${queryString}` : ''}`;

  try {
    const response = await fetch(url, config);
    const responseData = await response.json();

    if (response.ok) {
      return {
        ...response,
        data: responseData,
        error: null,
        status: response.status,
        headers: response.headers,
        ok: response.ok,
        redirected: response.redirected,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
        clone: response.clone,
        body: response.body,
        bodyUsed: response.bodyUsed,
        arrayBuffer: response.arrayBuffer,
        blob: response.blob,
        formData: response.formData,
        json: response.json,
        text: response.text,
      };
    } else {
      if (response.status === 403) {
        throw new AuthError(responseData);
      }
      throw new HttpError(response.status, responseData);
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error;
    }
    throw new Error('Network error');
  }
}

export const fetchWrapper = {
  get: <T>(endpoint: string, options?: RequestOptions) =>
    fetchWrapperFunction<T>(endpoint, { ...options, method: 'GET' }),
  post: <T>(endpoint: string, body: Record<string, unknown>, options?: RequestOptions) =>
    fetchWrapperFunction<T>(endpoint, { ...options, method: 'POST', body }),
  put: <T>(endpoint: string, body: Record<string, unknown>, options?: RequestOptions) =>
    fetchWrapperFunction<T>(endpoint, { ...options, method: 'PUT', body }),
  delete: <T>(endpoint: string, options?: RequestOptions) =>
    fetchWrapperFunction<T>(endpoint, { ...options, method: 'DELETE' }),
  patch: <T>(endpoint: string, body: Record<string, unknown>, options?: RequestOptions) =>
    fetchWrapperFunction<T>(endpoint, { ...options, method: 'PATCH', body }),
};

export default fetchWrapper;
